<template>
    <div v-if="appStore.state.apiError">
        <error-view/>
    </div>
    <div v-else-if="appStore.state.initialLoading && !$route.meta.allowUnauthorized">
        <loading-view/>
    </div>
    <div v-else class="bg-white">
        <main class="h-full" :class="{'scrolling-up': appStore.state.showBackToTop}">
            <router-view></router-view>
      </main>
    </div>
    <iu-modal-feedback v-if="appStore.state.showUserFeedback" />

    <!-- TODO: Fix Tailwind for autodetect `bg-${color}` classes -->
    <span class="hidden
    bg-orange border-orange bg-orange-light hover:bg-orange-light border-r-orange border-r-orange-light border-orange-light
    bg-blue border-blue hover:bg-blue-light bg-blue-light border-r-blue-light border-blue-light border-r-blue-lighter
    bg-green border-green hover:bg-green-light lg:bg-green-light border-green-light
    bg-yellow border-yellow hover:bg-yellow-light border-r-yellow-light border-yellow-light
    bg-pink border-pink hover:bg-pink-light border-pink-light bg-pink-light
    bg-red border-red bg-red-light hover:bg-red-light border-red-light
    bg-cta-light border-cta-light bg-cta-light-light hover:bg-cta-light-light border-cta-light-light
    bg-gray-50 border-gray-50 bg-gray-50-light hover:bg-gray-50-light border-gray-50-light
    h-[150px] h-[300px] mb-[46px] mb-[94px] mb-[76px] top-[210px] top-[175px]
    "></span>

</template>

<script setup lang="ts">
import appStore from '@/stores/app.store';
import LoadingView from "@/pages/LoadingView.vue";
import { useGtm } from "@gtm-support/vue-gtm";
import IuModalFeedback from "@/components/modal/IuModalFeedback.vue";
import ErrorView from "@/pages/ErrorView.vue";
import {
  activateAbTasty,
  activateMouseflow,
  activateProductFruits,
  activateUsercentrics
} from '@/services/userhelper.service'
import determineScrollDirection from "@/functions/determineScrollDirection";
import accountStore from "@/stores/account.store";
import {useRoute, useRouter} from "vue-router";
import {MetadataKey} from '@/types/enums'

const $gtm = useGtm()
const router = useRouter()

document.title = appStore.state.isB2b ? 'IU Unternehmensportal' : ((window?.location?.href?.includes('lebenslauf-generator')) ? 'Lebenslaufgenerator' : 'IU Bewerbungsportal')

activateUsercentrics()

window.addEventListener("ucEvent", (event:any) => {
  console.log('[Usercentrics] ucEvent triggered:', event.detail)

  if(!event.detail || !(event.detail.event === 'consent_status' || event.detail.event === 'consents_initialized')) {
    return
  }

  if('Google Tag Manager' in event.detail && $gtm) {
    $gtm.enable(event.detail['Google Tag Manager'])
    console.log('[Usercentrics] GoogleTagManager active: ', event.detail['Google Tag Manager'])
  }

  if('Mouseflow' in event.detail && event.detail['Mouseflow'] === true) {
    activateMouseflow()
    console.log('[Usercentrics] Mouseflow actived')
  }

  if('Product Fruits' in event.detail && event.detail['Product Fruits'] === true) {
    activateProductFruits(window,document,'https://app.productfruits.com/static/script.js','de')
    console.log('[Usercentrics] ProductFruits actived')
  }

  // check calendly consent
  if('Calendly' in event.detail && event.detail['Calendly'] === true) {
    appStore.setCalendlyConsentGiven(true)
    console.log('[Usercentrics] Calendly consent given')
  }

  // check calendly consent
  if('AB Tasty' in event.detail && event.detail['AB Tasty'] === true) {
    activateAbTasty()
    console.log('[Usercentrics] AB Tasty consent given')
  }


})

window.addEventListener('scroll', determineScrollDirection)


window.addEventListener('initialLoadingCompleted', () => {
  if(appStore.state.isB2b) {
    return
  }

  if (accountStore.state.account && !accountStore.getMetadataValue(MetadataKey.ONBOARDING_DONE)) {
    console.log('onboardingDone not done', accountStore.getMetadataValue(MetadataKey.ONBOARDING_DONE))
    router.push({ name: 'ds.onboarding' })
  }
})

</script>
