<template>
    <div class="flex flex-col mb-4">
        <iu-form-field-label v-if="label" :name="name" :tooltipText="tooltipText">
            {{ label }}{{ isRequired ? '*' : '' }}
        </iu-form-field-label>
        <div class="relative">
            <textarea :id="name"
                      v-model="props.modelValue.$model"
                      class="iu-textarea"
                      :class="disabled ? 'bg-gray-100 text-gray-700' : ''"
                      :aria-labelledby="`${name}-label`"
                      :name="name"
                      cols="30"
                      rows="4"
                      :placeholder="placeholder"
                      :maxlength="maxChars"
                      :required="isRequired"
                      :disabled="disabled"
                      @blur="trackEvent('UserInput', `updated field: ${name}`)"
            />
        </div>
        <div v-if="showCharacterLimit" class="flex justify-between">
            <p class="text-gray-500 mt-1">noch {{ maxChars - (props.modelValue.$model?.length ?? 0) }} Zeichen</p>
        </div>
        <p v-if="modelValue.$error" class="text-error mt-1 ml-4 flex items-center">
            <svg-icon name="times-square" class="shrink-0 mr-2"/>
            {{ $t(modelValue.$errors[0]?.$message, [label]) }}
        </p>
    </div>
</template>

<script setup lang="ts">
import IuFormFieldLabel from "@/components/elements/IuFormFieldLabel.vue";
import {trackEvent} from '@/services/gtm.service';
import {Validation} from "@vuelidate/core";
import SvgIcon from "@/components/SvgIcon.vue";
import {computed} from "vue";

const props = defineProps<{
    modelValue: Validation, // vuelidate model
    name: string,
    label?: string,
    disabled?: boolean,
    placeholder?: string,
    tooltipText?: string,
    maxCharacters?: number
    showCharacterLimit?: boolean,
}>()

const isRequired = computed(() => props.modelValue.hasOwnProperty('required'))
const maxChars = computed(() => props.maxCharacters ?? 1600)

</script>

<style>
textarea.iu-textarea {
    @apply appearance-none w-full px-4 py-3 bg-transparent text-black border border-black bg-white leading-relaxed;
    /*@apply border h-13 w-full  border-box appearance-none focus:bg-white focus:ring-cta focus:shadow-gray bg-gray-50*/
}

textarea.iu-textarea:disabled {
    @apply bg-gray-50 border-gray-200;
}

textarea.iu-textarea:hover:enabled:not(:focus) {
    @apply bg-gray-200 border-black;
}

textarea.iu-textarea:focus {
    @apply bg-white text-black outline-black outline-1 -outline-offset-1 ring-black;
}
</style>
