import {reactive, readonly} from 'vue'
import UserType from '@/units/b2b/types/UserType'
import apiService from '@/services/api.service'
import ApiError from '@/types/ApiError'
import appStore from '@/stores/app.store'
import {
  ConsentStatus,
  ConsentType as ConsentTypeEnum,
  EnumsB2bPermissions,
  EnumsDomStorage,
  FrontendErrorCode,
  MetadataKey
} from '@/types/enums'
import CompanyType from "../types/CompanyType";
import b2bAppStore from "@/units/b2b/stores/b2b-app.store";
import DocumentObject from "@/types/DocumentObject";
import {th} from 'date-fns/locale'
import ConsentType from "@/units/b2b/types/ConsentType";

const state = reactive({
  user: {} as UserType,
  companies: [],
  currentCompany: {} as CompanyType,
  hasPermissionTo: {} as { [key: string]: boolean }
});

const setUserConsents = (consents: Array<ConsentType>) => {
  state.user.consents = consents
}

const setUserData = (data: UserType) => {
  console.log('[B2B userStore] (setUserData)', state, data)
  state.user = {...data, ...data.employee} // TODO: Why? deprecated?

  // set permissions
  for (let permissionKey in EnumsB2bPermissions) {
    let val = EnumsB2bPermissions[permissionKey as keyof typeof EnumsB2bPermissions]
    state.hasPermissionTo[val] = !!state.user.isOwner || !!state.user.permissions?.includes(val)
  }
}

// TODO: Set (re)set current company
// TODO: split companies for selects
const setCompanyData = (data: Array<CompanyType> | CompanyType) => {
  if (Array.isArray(data)) {
    if (data.length === 1 && !localStorage.getItem(EnumsDomStorage.B2B_ACTIVE_COMPANY_ID)) {
      if (data[0].invitationPending) {
        b2bAppStore.setShowCompanySelectOverlay(true)
        return
      }
      setActiveCompany(data[0].id).then(r => null)
    } else if (data.length <= 0 || (data.length > 1 && !localStorage.getItem(EnumsDomStorage.B2B_ACTIVE_COMPANY_ID))) {
      // more than one company for this user, and non ist selected as active -> return and show selectoverlay
      b2bAppStore.setShowCompanySelectOverlay(true)
      return
    }
    b2bAppStore.setShowCompanySelectOverlay(false)
    const currentComp = data.find(comp => comp.id === Number(localStorage.getItem(EnumsDomStorage.B2B_ACTIVE_COMPANY_ID)))
    state.currentCompany = currentComp || data[0]
  } else {
    state.currentCompany = data
  }
  if (!state.currentCompany.bankAccount) {
    console.log('no Bankaccount, set!')
    state.currentCompany.bankAccount = {
      ownerName: '',
      bankName: '',
      iban: '',
      bic: '',
      city: '',
      sepaConfirmed: false,
      paymentType: '',
      mandateReference: '',
    } as CompanyType['bankAccount']
  }
  console.log('setCompanyData done:', state.currentCompany)
}

const fetchUserData = async () => {
  // load account-data
  return await apiService.b2b.getUser().then((result) => {
    console.log('fetchUserData', result.data)
    setUserData(result.data);
    setCompanyData(result.data.companies);
  }, (error: ApiError) => {
    appStore.setApiError(error.code ?? FrontendErrorCode.FETCH_ERROR, error.message)
  })
}

const updateUserData = async (data: object) => {
  return await apiService.b2b.patchUser(data).then((result) => {
    setUserData(result.data);
  })
}

const fetchCompanyData = async () => {
  // load account-data
  return await apiService.b2b.getCompany(state.currentCompany.id).then((result) => {
    console.log('fetchCompanyData', result.data)
    setCompanyData(result.data);
  }, (error: ApiError) => {
    appStore.setApiError(error.code ?? FrontendErrorCode.FETCH_ERROR, error.message)
  })
}

const updateCompanyMasterData = async (data: object) => {
  return await apiService.b2b.patchCompanyMasterData(state.currentCompany.id, data).then((result) => {
    setCompanyData(result.data);
  })
}

const updateCompanyPortrait = async (data: object) => {
  return await apiService.b2b.patchCompanyPortrait(state.currentCompany.id, data).then((result) => {
    setCompanyData(result.data);
  })
}

const updateCompanyPaymentDetails = async (data: object) => {
  return await apiService.b2b.patchCompanyPaymentDetails(state.currentCompany.id, data).then((result) => {
    setCompanyData(result.data);
  })
}

const updateCompanyPaymentType = async (data: object) => {
    return await apiService.b2b.patchCompanyPaymentType(state.currentCompany.id, data).then(_ => {
        state.currentCompany.bankAccount.paymentType = data.paymentType
    })
}

const setActiveCompany = async (companyId: number) => {
  localStorage.setItem(EnumsDomStorage.B2B_ACTIVE_COMPANY_ID, String(companyId))
  return await apiService.b2b.setActiveCompany(companyId)
}

const fetchBankData = async (iban: string) => {
    return await apiService.b2b.getBankDetailsByIban(iban)
}

const updateMetadata = async (key: string, value: any) => {
  if (typeof state.user.metadata !== 'object') {
    state.user.metadata = {}
  }

  state.user.metadata[key] = value

  return await apiService.b2b.putMetadata(state.user.metadata)
}

const getMetadataValue = (key: MetadataKey): null | any => {
  if (!state.user.metadata) {
    return null
  }

  return state.user.metadata[key]
}

const setProfilePicture = async (newProfilePicture: DocumentObject | null) => {
  state.user.profilePicture = newProfilePicture
}

const consentStatus = (type: ConsentTypeEnum) => {
  let filteredConsents = state.user.consents.filter(consent => consent.type === type)

  if (filteredConsents.length > 0) {
    return filteredConsents[0].status
  }

  return ConsentStatus.NOT_ANSWERED
}

  export default {
  state: readonly(state),
  setUserData,
  fetchBankData,
  fetchUserData,
  updateMetadata,
  updateUserData,
  updateCompanyMasterData,
  updateCompanyPortrait,
  updateCompanyPaymentDetails,
  updateCompanyPaymentType,
  fetchCompanyData,
  setActiveCompany,
  setProfilePicture,
  getMetadataValue,
  consentStatus,
  setUserConsents,
};
